.nav{
    height: 80px;
}

.welcome-nav-right-shade{
    height: 40px;
    width: 20px;
    background-color: #0000001c;
}
.wellbeauty-logo{
    height: 50px;
    margin: 5px;
}