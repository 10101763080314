.analyze-background-container {
    height: calc(100vh - 115px);
    position: relative;
    background: rgb(224,228,229);
    background: linear-gradient(180deg, rgba(224,228,229,1) 0%, rgba(222,223,225,1) 100%);

}

.analyze-background {
    background-image: url("/src/assets/img/AnalyzeDaf.jpg");
    background-repeat: no-repeat;
    height: 100%;
    background-size: contain;
    background-position: bottom left;
}

.analyze-type {
    height: 180px;
    position: relative;
    right: 50px;
    top: 50px;
    width: 260px;
}

.modal {
    background-color: white;
    border-radius: 15px;
    padding: 10px !important;
}

.learn-more-button {
    border: 2px solid #969696 !important;
    width: 120px;
    padding: 15px 5px !important;
    top: 260px;
    border-radius: 12px !important;
    color: #969696 !important;
    right: 40px;
    position: absolute !important;
}

.start-analyze-button {
    position: absolute !important;
    bottom: 110px;
    right: calc(50vw - 150px );
    padding: 15px !important;
    width: 300px;
    border: 1px solid rgb(171, 136, 190) !important;
    border-radius: 15px !important;
    background: linear-gradient(90deg, rgba(95, 51, 137, 1) 0%, rgba(114, 73, 153, 1) 100%) !important;
    background: -moz-linear-gradient(90deg, rgb(95, 51, 137) 0%, rgb(114, 73, 153) 100%) !important;
    background: -webkit-linear-gradient(90deg, rgb(95, 51, 137) 0%, rgb(114, 73, 153) 100%) !important;
    background: -ms-linear-gradient(90deg, rgb(95, 51, 137) 0%, rgb(114, 73, 153) 100%) !important;
}

